@charset "UTF-8";


/*=======================================
        Imports
=======================================*/

// Normalize 3.0.3
@import "vendor/normalize/normalize";

/**
 * Foundation for Sites by ZURB
 * Version 6.4.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */

// Dependencies for foundation
@import 'vendor/sassy-lists/helpers/missing-dependencies';
@import 'vendor/sassy-lists/helpers/true';
@import 'vendor/sassy-lists/functions/contain';
@import 'vendor/sassy-lists/functions/purge';
@import 'vendor/sassy-lists/functions/remove';
@import 'vendor/sassy-lists/functions/replace';
@import 'vendor/sassy-lists/functions/to-list';

// Settings
// import your own `settings` here or
// import and modify the default settings through
// @import 'settings/settings';
@import "partials/foundation-settings";

// Sass utilities
@import 'vendor/foundation/util/util';

// Global variables and styles
@import 'vendor/foundation/global';

// Components
@import 'vendor/foundation/grid/grid';
// @import 'vendor/foundation/xy-grid/xy-grid';
@import 'vendor/foundation/typography/typography';
@import 'vendor/foundation/forms/forms';
@import 'vendor/foundation/components/visibility';
@import 'vendor/foundation/components/float';
@import 'vendor/foundation/components/button';
@import 'vendor/foundation/components/button-group';
@import 'vendor/foundation/components/accordion-menu';
@import 'vendor/foundation/components/accordion';
// @import 'vendor/foundation/components/badge';
// @import 'vendor/foundation/components/breadcrumbs';
@import 'vendor/foundation/components/callout';
@import 'vendor/foundation/components/card';
@import 'vendor/foundation/components/close-button';
@import 'vendor/foundation/components/drilldown';
@import 'vendor/foundation/components/dropdown-menu';
@import 'vendor/foundation/components/dropdown';
@import 'vendor/foundation/components/flex';
@import 'vendor/foundation/components/responsive-embed';
@import 'vendor/foundation/components/label';
@import 'vendor/foundation/components/media-object';
@import 'vendor/foundation/components/menu';
@import 'vendor/foundation/components/menu-icon';
// @import 'vendor/foundation/components/off-canvas';
// @import 'vendor/foundation/components/orbit';
@import 'vendor/foundation/components/pagination';
// @import 'vendor/foundation/components/progress-bar';
// @import 'vendor/foundation/components/reveal';
// @import 'vendor/foundation/components/slider';
// @import 'vendor/foundation/components/sticky';
// @import 'vendor/foundation/components/switch';
@import 'vendor/foundation/components/table';
@import 'vendor/foundation/components/tabs';
@import 'vendor/foundation/components/title-bar';
@import 'vendor/foundation/components/top-bar';
@import 'vendor/foundation/components/thumbnail';
// @import 'vendor/foundation/components/tooltip';
// @import 'vendor/foundation/prototype/prototype';

@mixin foundation-everything(
  $flex: true,
  $prototype: false
) {
  @if $flex {
    $global-flexbox: true !global;
  }

  @include foundation-global-styles;
  @if not $flex {
    @include foundation-grid;
  }
  @else {
    @if $xy-grid {
      @include foundation-xy-grid-classes;
    }
    @else {
      @include foundation-flex-grid;
    }
  }
  @include foundation-typography;
  @include foundation-forms;
  @include foundation-button;
  @include foundation-accordion;
  @include foundation-accordion-menu;
  // @include foundation-badge;
  // @include foundation-breadcrumbs;
  @include foundation-button-group;
  @include foundation-callout;
  @include foundation-card;
  @include foundation-close-button;
  @include foundation-menu;
  @include foundation-menu-icon;
  @include foundation-drilldown-menu;
  @include foundation-dropdown;
  @include foundation-dropdown-menu;
  @include foundation-responsive-embed;
  @include foundation-label;
  @include foundation-media-object;
  // @include foundation-off-canvas;
  // @include foundation-orbit;
  @include foundation-pagination;
  // @include foundation-progress-bar;
  // @include foundation-slider;
  // @include foundation-sticky;
  // @include foundation-reveal;
  // @include foundation-switch;
  @include foundation-table;
  @include foundation-tabs;
  @include foundation-thumbnail;
  @include foundation-title-bar;
  // @include foundation-tooltip;
  @include foundation-top-bar;
  @include foundation-visibility-classes;
  @include foundation-float-classes;

  @if $flex {
    @include foundation-flex-classes;
  }

  @if $prototype {
    @include foundation-prototype-classes;
  }
}

@include foundation-everything();

@import "partials/fonts";
@import "partials/typography";

// Flexslider 2.4.0
@import "vendor/flexslider/flexslider";

// Fancybox 2.1.5
@import "vendor/fancybox/jquery.fancybox";

// ImageLightBox by http://osvaldas.info/image-lightbox-responsive-touch-friendly
@import "vendor/imagelightbox/imagelightbox";

// Architecture Settings
@import "partials/hlm-mixins";
@import "partials/hlm-functions";

// HLM Base Styles
@import "partials/base";
@import "partials/articles";


/*=======================================
          HLM Modules
=======================================*/
@import "partials/module-includes";

// Gallery Options
@import "modules/gallery/ThumbnailLayout";
// @import "modules/gallery/ThumbnailSlider";

// HLM Sections
@import "sections/header";
@import "sections/layouts";
@import "sections/footer";
@import "sections/page-home";
@import "sections/sub-banner";

// HLM Other
@import "partials/accordions";
@import "partials/coupons";
@import "partials/blog-grid";
// @import "partials/events";
@import "partials/top-bar";
@import "partials/form-controls";
@import "partials/myce-callouts";
@import "partials/subnav";
// @import "partials/products";
// @import "partials/food-menus";
@import "partials/sml";

// HLM PPC
// @import "ppc/main-ppc";

/*=======================================
      Custom Site Styles
=======================================*/
body{
  overflow-x:hidden;
}
//Home Content//
//============//
section.content.home-page{
  background:#fff;
}
.home-heading{
  text-shadow: 0 1px 1px #ffffff;
  color: #000000;
  font-family: $header-font-family;
  font-size: rem-calc(24);
  font-weight: 400;
  margin-bottom:rem-calc(20);
}

.home-wrapper{
  display:flex;
  justify-content: space-around;
  align-items: center;
  flex-flow:row wrap;

  .block{
    margin:rem-calc(10 15 20);
  }
}


//Home Page Footer//
//================//
footer.home-footer{
  padding:rem-calc(30);
  box-shadow: inset 0 2px 13px rgba(0, 0, 0, 0.47);
  border: 1px solid rgba(255, 255, 255, 0.39);
  background-color: $secondary-color;
  text-align:center;
  .footer-wrapper{
    display:flex;
    justify-content: center;
    flex-flow:row wrap;
    margin-bottom:rem-calc(10);
    .phone{
      color: #cacaca;
      font-family: $header-font-family;
      font-weight: 700;
      line-height: 40px;
      font-size:rem-calc(20);
      text-decoration:none;
    }
    .address, span{
      color: #cacaca;
      font-family: $header-font-family;
      font-weight: 400;
      line-height: 40px;
      font-size:rem-calc(20);
    }
    span{
      margin:rem-calc(0 10);
      display:none;
    }

    @include breakpoint(large){
      span{
        display:block;
      }
    }
  }

  .footer-copyright, .footer-disclaimer, .footer-web-credit ul li a{
    color: #cacaca;
    font-family: $body-font-family;
    font-size: rem-calc(12);
    font-weight: 300;
  }

  .footer-web-credit ul{
    display:flex;
    justify-content: center;
  }
}

//Content Styles//
//==============//
.content-contact-info p{
  margin-top:rem-calc(25);
  color: $primary-color;
  font-weight:600;
  font-size:rem-calc(18);

  a{
    text-decoration:none;
  }
}

//Employment Form//
//===============//
.employment-form{
  .form_required_div.form-required-div{
    color: $secondary-color;
    font-family: $body-font-family;
    font-size: rem-calc(18);
    font-style:italic;
    font-weight: 400;
  }

  .fm-question{
    font-weight:600;
    color:#222;
    font-size:rem-calc(18);
  }

  input[type="text"], input[type="email"]{
    height:36px !important;
    background:#ededed !important;
    border:1px solid $secondary-color !important;
  }

  input[type="checkbox"]{
    height:14px !important;
    width:14px !important;
    background:#fff !important;
    border:1px solid $secondary-color !important;
    border-radius:0 !important;
  }

  textarea{
    height:84px !important;
    background:#ededed !important;
    border:1px solid $secondary-color !important;
  }

  #left, #right{
    & > div{
      margin:rem-calc(15) auto;
    }
  }

  #right{
    .grey-section{
      background:#ebebeb;
      padding:rem-calc(40 40 45 30);
      margin-top:rem-calc(40);
      .grey-heading{
        color: $secondary-color;
        font-family: $header-font-family;
        font-size: rem-calc(22);
        font-weight: 400;
        line-height: 29.99px;
        margin-bottom:rem-calc(20);
      }

      .red-heading{
        color: $primary-color;
        font-size: rem-calc(18);
        font-weight: 700;
        font-style:italic;
        line-height: 24px;
        margin-bottom:rem-calc(25);
      }
    }
  }

  .fm-submit-container input[type="submit"]{
    max-width:rem-calc(230);
    box-shadow:none;
    margin:rem-calc(10) auto;
  }

  .form-post-heading{
    font-family:$body-font-family;
    color: #222222;
    font-size: rem-calc(18);
    font-weight: 600;
    line-height: 26px;
    text-align:center;
    margin:rem-calc(40) auto 0;
  }

  @include breakpoint(large){
    #left{
      padding-right:rem-calc(20);
    }
    #right{
      padding-left:rem-calc(20);
    }

    // Container for questions related to skills  
    .skills-wrapper {
      column-count: 2;
      page-break-after: avoid;
    }
  }
}

section.contact-callouts{
  background:#e1e1e1;
  padding:rem-calc(30 0);

  .heading{
    color: #000000;
    font-family: $header-font-family;
    font-size: rem-calc(30);
    font-weight: 400;
    text-align:center;
    margin-bottom:rem-calc(35);
  }

  .contact-wrapper{
    display:flex;
    justify-content: space-between;
    align-items: center;
    flex-flow:row wrap;

    .contact-block{
      margin:rem-calc(15) auto;
      padding:rem-calc(0 10);
      a{
        text-shadow: 0 1px 1px #ffffff;
        color: $primary-color;
        font-family: $header-font-family;
        font-size: rem-calc(32);
        font-weight: 700;
        text-decoration:none;

        img{
          margin-right:rem-calc(10);
          margin-left:rem-calc(10);
        }
      }
    }
  }

  @include breakpoint(large){
    padding:rem-calc(50 0);
  }
}


//Projects List//
//=============//
.projects-heading{
  border-bottom:5px solid $primary-color;
  .project-list-heading{
    color: $secondary-color;
    font-family: $header-font-family;
    font-size: rem-calc(22);
    font-weight: 400;
    text-align:left;
    margin-bottom:rem-calc(5);
  }
}
.project_block{
  padding:rem-calc(10 0);
  border-bottom:1px solid #ebebeb;

  color: #222222;
  font-family: $body-font-family;
  font-size: rem-calc(20);
  font-weight: 400;

  span{
    font-weight:600;
  }

  @include breakpoint(large){
    padding:rem-calc(30 0);
  }
}

.employ-heading, .skills-heading, .rating-heading{
  @extend h3;
  color: #333;
  text-align:center;
  font-style:normal;
  margin-top:rem-calc(20);
  @include breakpoint(large){
    text-align:left;
  }
}

.rating-heading{
  @include breakpoint(large){
    text-align:right;
  }
}

.employment-form{
  select.form_select{
    height:rem-calc(36);
  }
}


ul.custom-list-wrap {
  li {
    display: inline;
    clear: right;
    &::after {
      content: " ";
      display: block;
      width: 100%;
    }
  }
}
