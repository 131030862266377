/*=======================================
		Sliding Gallery Banner
=======================================*/

.banner.sliding-gallery-banner{

	.flexslider, .flexslider .slides{
		max-width: 100%;
		top: 0;
		left: 0;
		right: 0;
		margin: 0 auto;
		width: 100%;
		height: 100vh;
		max-height: rem-calc(400);

		li{
			height: 100%;
		}
	}

	.slide-img{
		width: 100%;
		height: 100vh;
		max-height: rem-calc(400);

		img{
			height: 100%;
		}
	}

	.gallery-title{
		position: absolute;
		top: rem-calc(90);
		border-left:5px solid $primary-color;
		background-color: rgba(#ffffff, 0.7);
		padding:rem-calc(25 60 25 30);
		right:rem-calc(10);
		left:rem-calc(10);

		.gal-caption{
			text-shadow: 0 1px 1px #ffffff;
			color: $secondary-color;
			font-family: $body-font-family;
			font-size: rem-calc(22);
			font-weight: 600;
			font-style:italic;
			line-height: 30px;
			text-align:left;

			span{
				display:flex;
				text-shadow: 0 1px 1px #ffffff;
				color: $primary-color;
				font-family: $header-font-family;
				font-size: rem-calc(30);
				font-weight: 700;
				margin-bottom:rem-calc(15);

				img{
					width:rem-calc(43);
					height:rem-calc(28);
					margin-top:rem-calc(2);
				}
			}
		}
	}

	.flex-control-nav{
		bottom: rem-calc(20);
		z-index: 20;
		display:flex;
		justify-content: center;
		left:0;
		right:0;
		@extend .row;
	}

	.flex-control-paging li a{
		width: rem-calc(26);
		height: rem-calc(26);
    	border: 2px solid #fff;
		background:transparent;

		&.flex-active{
			background:#fff;
		}
	}

	.banner-links{
		background:rgba(#000000, 0.6);
		bottom:0;
		width:100%;

		.link-wrapper{
			display:flex;
			justify-content: space-around;
			align-items: center;
			flex-flow:row wrap;
			padding-top:rem-calc(40);
			padding-bottom:rem-calc(40);

			.link{
				text-align:center;
				margin:rem-calc(15) auto;
				width:100%;

				a{
					text-shadow: 0 1px 1px rgba(0, 0, 0, 0.35);
					color: #ffffff;
					font-family: $header-font-family;
					font-size: rem-calc(18);
					font-weight: 700;
					text-decoration:none;
					text-transform: uppercase;
					line-height:24px;
					transition:all .2s ease;
					&:hover{
						text-decoration:underline;
					}
				}

				&:nth-of-type(even){
					width:3px;
					min-height:65px;
					background:$primary-color;
					display:none;
				}
			}
		}
	}

	.button-container{
		position:absolute;
		left:0;
		right:0;
		bottom: 40%;
		text-align:center;

		.button{
			max-width:rem-calc(330);
			color: #ffffff;
			font-family: $header-font-family;
			font-size: rem-calc(20);
			font-weight: 700;
			padding:rem-calc(20 25);

			img{
				display:inline;
				margin-left:rem-calc(5);
			}
		}
	}

	@include breakpoint(medium){
		.banner-links .link-wrapper{
			.link{
				width:45%;
			}
		}
	}

	@include breakpoint(large){

		.flexslider{
			min-height: rem-calc(650);
			max-width: rem-calc(1230);
			margin: 0 auto;
		}

		.flexslider .slides{
			min-height: rem-calc(650);
		}
	
		.slide-img{
			position: absolute;
			min-height: rem-calc(650);
			max-height: rem-calc(650);
			overflow: hidden;

			img{
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				min-height: 100%;
				height: auto;
				width: auto;
				min-width: 100%;
				max-width: none;
			}
		}

		.banner-links{
			position:absolute;

			.link-wrapper{
			
				.link{
					width:auto;
					&:nth-of-type(even){
						display:block;
					}
				}
			}
		}

		.flex-control-nav{
			bottom: 32%;
			margin: 0 auto;
		}

		.gallery-title{
			font-size: rem-calc(42);
			max-width:rem-calc(400);
			&#index-1{
				left: rem-calc(15);
				right:unset;
			}

			&#index-2, &#index-3{
				right: rem-calc(15);
				left:unset;
			}

			.gal-caption {
				margin: 0;
			}
		}
	}
}
